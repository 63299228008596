import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import SelectBox from "../../../components/Common/Form/SelectBox";
import { EcReturn, ProjectInformation } from "../../../types/pages/EcWebsettlement/purchase_ec_return";
import { PurchaseEcReturnFormValues } from "../../../types/pages/EcReturn/Websettlement/purchase_ec_return";
import { Control, UseFormGetValues, UseFormWatch } from "react-hook-form";
import EcReturnForm from "./SelectReturn/EcReturnForm";

type Props = {
  ecReturns: EcReturn[],
  projectInformation: ProjectInformation,
  control: Control<PurchaseEcReturnFormValues, any>,
  setValue: any,
  getValues: UseFormGetValues<PurchaseEcReturnFormValues>,
  watch: UseFormWatch<PurchaseEcReturnFormValues>,
  totalAmount: () => number,
  priceByAmount: () => number[],
  totalPriceByAmount: () => number,
  commission: number,
  is_draft?: boolean
}

const SelectReturn = ({
  ecReturns,
  projectInformation,
  control,
  setValue,
  getValues,
  watch,
  totalAmount,
  totalPriceByAmount,
  priceByAmount,
  commission,
  is_draft
}: Props) => {

  const additionalAmountOptions = [
    "0",
    "500",
    "1,000",
    "1,500",
    "2,000",
    "2,500",
    "3,000",
    "3,500",
    "4,000",
    "4,500",
    "5,000",
    "5,500",
    "6,000",
    "6,500",
    "7,000",
    "7,500",
    "8,000",
    "8,500",
    "9,000",
    "9,500",
    "10,000",
    "20,000",
    "30,000",
    "40,000",
    "50,000",
    "60,000",
    "70,000",
    "80,000",
    "90,000",
    "100,000",
    "200,000",
    "300,000",
    "400,000",
    "500,000",
    "600,000",
    "700,000",
    "800,000",
    "900,000",
    "1,000,000",
    "2,000,000",
    "3,000,000",
    "4,000,000",
    "5,000,000",
    "6,000,000",
    "7,000,000",
    "8,000,000",
    "9,000,000"
  ]

  const additionalAmountValues = [
    0,
    500,
    1000,
    1500,
    2000,
    2500,
    3000,
    3500,
    4000,
    4500,
    5000,
    5500,
    6000,
    6500,
    7000,
    7500,
    8000,
    8500,
    9000,
    9500,
    10000,
    20000,
    30000,
    40000,
    50000,
    60000,
    70000,
    80000,
    90000,
    100000,
    200000,
    300000,
    400000,
    500000,
    600000,
    700000,
    800000,
    900000,
    1000000,
    2000000,
    3000000,
    4000000,
    5000000,
    6000000,
    7000000,
    8000000,
    9000000
  ]

  return (
    <>
      <div className="mb-[30px] flex">
        <div className="h-[40px] bg-[#1F2122] w-[61%] relative flex items-center justify-center text-white text-sm font-bold">
          <span className="text-[#1F2122] bg-[#768084] rounded-[50%] inline-block mr-1 w-5 h-5 text-center">
            1
          </span>
          支援内容の入力
          <div className="right-triangle-thin right-[-7px] absolute"></div>
        </div>
        <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
          2
        </div>
        <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
          3
        </div>
        <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
          4
        </div>
      </div>
      {ecReturns.map((ecReturn, index) => (
        <EcReturnForm
          ecReturn={ecReturn}
          ecReturnIndex={index}
          ecReturnLength={ecReturns.length}
          control={control}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          priceByAmount={priceByAmount}
        />
      ))}
      <div className="pt-[40px]">
        <div className="mb-10 p-5 bg-[#F4F6F8]">
          <p className="font-bold mb-2">リターンに関するお問い合わせ先</p>
          <a href={`mailto:${projectInformation.owner_email}`} className="link-hover-none underline">{projectInformation.owner_email}</a>
        </div>
        <div className="bg-[#F4F6F8] mt-[40px] p-5">
          <p className="mb-5 text-sm font-medium">
            あなたの気持ちをリターン購入金額に上乗せしよう
          </p>
          <div className="mb-[10px] flex items-end justify-between">
            <SelectBox
              id="additional_amount"
              name="additional_amount"
              placeholder="上乗せ金額を選択してください"
              control={control}
              options={additionalAmountOptions}
              values={additionalAmountValues}
            />
            <span className="font-medium ml-1">円</span>
          </div>
          <p className="text-[#768084] text-xs">
            ※500円以上の支援をお願いします
          </p>
        </div>
        <div className="pb-[40px] pt-[40px] border-[#D2DFE6] border-b">
          <p className="font-bold">合計の支援金額(税込)</p>
          <div className="text-[34px] mb-[10px] text-right font-bold">
            {totalAmount().toLocaleString()}
            <span className="text-lg">円</span>
          </div>
          <p className="text-[#768084] text-sm mb-1">内訳:</p>
          <div className="flex items-end flex-col">
            <div className="text-xs mb-1">
              リターンの購入金額: {totalPriceByAmount().toLocaleString()}円
            </div>
            <div className="text-xs mb-1">
              上乗せ支援:{Number(watch("additional_amount")).toLocaleString()}円
            </div>
            <div className="text-xs">
              プラットフォーム利用料:{commission}円
            </div>
          </div>
        </div>
        <div className="pt-[40px]">
          <div className="sm:mb-[60px] mb-10">
            <p className="font-bold mb-6">プラットフォーム利用料とは</p>
            <p className="mb-[10px]">クラウドファンディングのプロジェクトの支援時にプラットフォーム利用料をいただき、その一部を『スポチュニティ基金』として社会貢献活動を行う団体へ寄付すると共に、安心安全にクラウドファンディングが利用できるシステム環境の提供や運営のために利用いたします。</p>
            <p>詳しくは
              <a href="https://corp.spportunity.com/platform-fee/" target="_blank" rel="noopener noreferrer" className="underline link-hover-none">
                こちら
              </a>
            </p>
          </div>
          <div className="sm:mb-[60px] mb-10">
            <p className="font-bold mb-6">リターンについて</p>
            <p>リターンはご支援いただいた方、全員へお渡しいたします。</p>
            <p>リターンが配布されるのは必ずしも購入直後ではありませんのでご注意下さい。</p>
            <p>スポチュニティはプロジェクトオーナーのプロジェクト実行能力を保証するものではありません。プロジェクトの実行やリターンの発送（履行）などは、全てプロジェクトオーナーの責任のもと行われます。</p>
          </div>
        </div>
      </div>
      <div className="pt-[40px]">
        <p className="text-[#768084] mb-[40px] text-xs">
          リターンの購入にあたり、
          <a
            href="https://corp.spportunity.com/term_of_use/"
            className="link-hover-none underline"
          >
            スポチュニティのサービス利用規約
          </a>
          および
          <a
            href="https://corp.spportunity.com/privacy_policy/"
            className="link-hover-none underline"
          >
            プライバシーポリシー
          </a>
          に同意したものとみなされます 。
        </p>
        <div className="mb-[60px] sm:mx-auto h-[50px] sm:w-[290px]">
          <BaseButton
            text="お支払い情報の入力へ進む"
            fontSize="text-sm"
            isSubmit={true}
            disabled={is_draft}
          />
        </div>
        <div className="text-center mt-10">
          <a
            href={is_draft ? `${projectInformation.investment_path}${window.location.search}` : projectInformation.investment_path}
            className="left-icon icon-stick-arrow-left text-sm font-medium"
          >
            プロジェクトの詳細ページに戻る
          </a>
        </div>
      </div>
    </>
  );
};

export default SelectReturn;
